<template>
  <div id="mian">
    <div class="header">
      <span @click="toUrl('ledger_detail_jbxx', { id: $route.query.id })">基本信息</span>
      <span @click="toUrl('ledger_detail_jsxx', { id: $route.query.id })">结算信息</span>
      <span @click="toUrl('ledger_detail_glqd', { id: $route.query.id })" id="fzcolor">关联渠道</span>
      <span @click="toUrl('ledger_detail_dzzb', { id: $route.query.id })">电子账簿</span>
      <span @click="toUrl('ledger_detail_cpxx', { id: $route.query.id })">产品信息</span>
      <span @click="toUrl('ledger_detail_bccl', { id: $route.query.id })">补充材料</span>
    </div>
    <div v-auth="'ELECTRLED:MCHCOM:MCHLIST:SETTLEMENT'">
      <div class="desc">
        <h5 class="jsxx">关联渠道</h5>
      </div>
      <div class="tab1">
        <table class="tab">
          <tr class="tab_title">
            <td style="width: 171px">
              <p>商户名称</p>
            </td>
            <td style="width: 163px">
              <p>渠道属性</p>
            </td>
            <td style="width: 187px">
              <p>渠道编号</p>
            </td>
            <td style="width: 95px">
              <p>操作</p>
            </td>
          </tr>
          <tr>
            <td style="width: 171px">
              <p>{{ detail.mchName }}</p>
            </td>
            <td style="width: 163px">
              <p>{{ detail.allocMchId != null ? "分账商户" : "平台商户" }}</p>
            </td>
            <td style="width: 187px">
              <p>{{ detail.allocMchNo }}</p>
            </td>
            <td style="width: 95px">
              <p>
                <i class="lianjie" @click="editTab()" v-auth="'ELECTRLED:MCHCOM:MCHLIST:SETTLEMENT/EDIT'">编辑</i>
              </p>
            </td>
          </tr>
        </table>
      </div>
      <el-dialog title="关联渠道" :visible.sync="overAccountShow" width="490px">
        <ul class="overAccount_ul">
          <li class="itemBox">
            <div class="item">
              <div class="overAccount_ul_title"><i>*</i>关联商户</div>
              <el-select v-model="formData.mchId" placeholder="选择商户"  @change="channelMch">
                <el-option
                  v-for="v in mchDrop"
                  :key="v.mchId"
                  :label="v.mchName"
                  :value="v.mchId"
                ></el-option>
              </el-select>
            </div>
            <div class="item">
              <div class="overAccount_ul_title"><i>*</i>关联渠道属性</div>
              <el-select
                v-model="formData.type"
                placeholder="选择渠道属性"
                class="width100"
                @change="channelAttr"
              >
                <el-option
                  v-for="item in channelAttrDrop"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
            <div class="item">
              <div class="overAccount_ul_title"><i>*</i>关联渠道编号</div>
              <el-select
                v-model="formData.keyword"
                filterable
                clearable
                :filter-method="searchMchList"
                @visible-change="unfoldMchList"
                @clear="clearMchList"
                @change="changeMch"
                placeholder="选择渠道编号"
                :no-data-text="MerchantShopData=='' ? '无数据':'可搜索'"
              >
                <el-option
                  v-for="item in MerchantShopData"
                  :key="item.allocMchNo"
                  :label="item.allocMchNo"
                  :value="item.allocMchNo"
                >
                </el-option>
              </el-select>
            </div>
          </li>
        </ul>
        <span slot="footer" class="dialog-footer" style="text-align: center;">
          <el-button class="addBt" :disabled="buttonFlag" @click="sureAdd">确 定</el-button>
        </span>
      </el-dialog>        
    </div>
  </div>
</template>
<script>
import {ledgerDetail,} from "@/api/user/shopManager.js"; 
import { merchantsDrop } from "@/api/common.js";
import { searAlloc , setledger } from "@/api/user/shopManager.js";
export default {
  data() {
    return {
      mchDrop:[],//商户下拉选择
      MerchantShopData: [], //渠道编号下拉选择
      channelAttrDrop:[{label:"平台商户",value:"0"},{label:"分账商户", value:"1"}],//关联渠道属性
      MerchantItem:[],

      detail:{},
      overAccountShow: false, //新增/修改表单展示
      formData: {
        mchId:"",//关联商户
        type: "", //关联渠道属性 （0：平台，1分账）
        keyword:"",//关联渠道编号

        merchantId:"",
        allocMchId:"",
        allocMchNo:"",
      },
      tabData: [],
      editIndex: "",
      buttonFlag: false, //防止重复点击

    };
  },
  created() {
    this.getDrop();
    if (this.$route.query.id) {
      this.getDetail();
    }
  },
  mounted() {

  },
  methods: {
    // 获取详情
    getDetail() {
      ledgerDetail(this.$route.query.id).then((res) => {
        if (res.resultStatus) { 
          this.detail = res.resultData.mchDetail;  
        }
      });
    },
    // 修改关联渠道
    editTab() {
         this.formData.keyword = this.detail.allocMchNo;
          if(this.detail.allocMchId != null){
            this.formData.type = '分账商户'
          }else{
            this.formData.type = '平台商户'
          }
          this.mchDrop.filter((item) => {
            if(item.mchId == this.detail.merchantId){
              console.log(this.detail.merchantId)
              this.formData.mchId = item.mchName;
            }
          })      
         this.overAccountShow = true;
    },
    // 确认添加关联渠道
    sureAdd() {
      if (!this.formData.mchId) {
        this.$message.error("关联商户必选");
        return false;
      }
      if (!this.formData.type) {
        this.$message.error("关联渠道属性必选");
        return false;
      }
      if (!this.formData.keyword) {
        this.$message.error("关联渠道编号必选");
        return false;
      }
      let data = JSON.parse(JSON.stringify(this.detail));
      this.MerchantItem.filter((item) => {
            if(item.allocMchNo == this.formData.keyword){
              data.merchantId=item.merchantId;
              data.allocMchId=item.allocMchId;
              data.allocMchNo=item.allocMchNo;
            }
      })
      
      setledger(this.$route.query.id, data).then((res) => {
        if (res.resultStatus) {
          this.$message.success("保存成功");
          this.overAccountShow = false;
          this.getDetail();
        }
      });
    },

    // 获取商户下拉
    getDrop() {
      merchantsDrop().then((res) => {
        if (res) {
          this.mchDrop = res.resultData;
        }
      });
    },
    // 选中商户改变时
    channelMch(){
      this.formData.keyword="";
    },
    // 选中关联渠道属性改变时
    channelAttr(){
      this.formData.keyword="";
    },
    // 获取渠道编号下拉选择列表
    _searchAllMch(value) {

      let data = JSON.parse(JSON.stringify(this.formData));

      this.mchDrop.filter((item) => {
        if(item.mchName == this.formData.mchId || item.mchId == this.formData.mchId){
          data.mchId = item.mchId;
        }
      })

      if(this.formData.type == '分账商户' || this.formData.type == 1){
        data.type = 1
      }else{
        data.type = 0
      }

      this.MerchantShopData = [];
      let params = {
        mchId:data.mchId,
        type:data.type,
        keyword: value,
      };

      searAlloc(this.detail.mchName,params).then((res) => {
        if (res.resultStatus) {
          this.MerchantShopData = res.resultData.mchTrade;
          this.MerchantItem = res.resultData.mchTrade;
          if(this.MerchantShopData == ""){
            this.formData.keyword = "";
          }
          console.log(this.MerchantShopData);
        }
      });
      
    },
    // 渠道编号下拉列表输入搜索内容时
    searchMchList(val) {
      if (val) {
        let value = val;
        this._searchAllMch(value);
      } else {
        // 搜索内容为空时还原列表
        this._searchAllMch();
      }
    },
    // 渠道编号下拉框展开/收起时触发
    unfoldMchList(e) {
      if (e) {
        //  展开时
        this._searchAllMch();
      } else {
        //  折叠时
        this.MerchantShopData = [];
      }
    },
    // 渠道编号下拉搜索框清空搜索内容时
    clearMchList() {
      this.formData.keyword = ""; //清空选中的商户id
      this.keywordData = {};
      this._searchAllMch(); //重新获取渠道编号下拉列表
    },
    changeMch(e) {
      this.formData.keyword = e;
      let allocMchNo  = this.formData.keyword;
      let result = this.MerchantShopData.filter(e=>e.allocMchNo === allocMchNo)
      if(result && result.length > 0){
        console.log(result[0])
        this.keywordData = result[0];
      }
    }, 
    // 页面跳转
    toUrl(name,query) {
      this.$router.push({
        name: name,
        query: {
          ...query,
        },
      });
    },
  },
  beforeDestroy() {

  },
};
</script>
<style scoped>
@import "../../../assets/css/desc.css";
.tab1{
  min-height: auto !important;
}
.next {
  width: 120px;
  height: 32px;
  padding: 0;
  background: var(--themeColor);
  border-color: var(--themeColor);
  border-radius: 3px;
  line-height: 10px;
  margin-left: 10px;
  font-size: 12px;
}

.back {
  width: 120px;
  line-height: 10px;
  height: 32px;
  padding: 0;
  background: rgba(255, 255, 255, 1);
  border-color: #dce0e6;
  border: 1px solid rgba(220, 224, 230, 1);
  border-radius: 3px;
  color: var(--title);
  font-size: 12px;
}

.btn {
  width: 100%;
  margin-top: 50px;
  text-align: center;
  height: 33px;
}
.addBt {
  width: 60px;
  height: 32px;
  background-color: var(--themeColor);
  border-color: var(--themeColor);
  color: #ffffff;
  font-size: 12px;
  padding: 0;
}

.exitBt {
  width: 60px;
  height: 32px;
  background-color: #ffffff;
  color: var(--title);
  font-size: 12px;
  padding: 0;
}

.overAccount_ul {
  overflow: hidden;
  margin-top: 10px;
}

.overAccount_ul_title {
  height: 36px;
  font-size: 14px;
  line-height: 36px;
  color: #999999;
  width: 150px;
}

.overAccount_ul_title i {
  color: #48b8b6;
  padding-right: 3px;
}

.overAccount_ul .el-input,
.overAccount_ul .el-select {
  /* margin-bottom: 7px; */
}

.itemBox {
  width: 350px;
  margin: auto;
}
.itemBox .item{
  display: flex;
  margin-bottom: 15px;
}
</style>
